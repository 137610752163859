import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import ReportTypesArray from '../Mark/ReportTypesArray';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Link from '@mui/material/Link';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';

import PostFunction from "../API/postFunction";
import GetFunction from "../API/getFunction";

import { useState } from 'react';
import { useNavigate} from 'react-router-dom' 
import { useSelector, useDispatch } from 'react-redux'

export default function Post(props) {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const dispatch = useDispatch()

  const reportTypesArray = ReportTypesArray()

  // State permettant d'afficher l'icône correspondant à l'état de like (vrai ou faux)
  // Positionné à la valeur d'origine du post
  // On vérifie aussi si le post est déjà liké par l'utilisateur lui-même
  const [postLikes, setPostLikes] = useState({
    nbLikes : (props.postScreen === "PostWithComments" ? props.post.likes.length : props.post.nbLikes),
    iLike : (props.postScreen === "PostWithComments" ? props.post.likes.findIndex(item => item.likeAuthor === myProfile.pk) !== -1 : props.post.iLike)
  })

  // Pour gérer l'affichage du Popover avec la liste des spots
  const [spotlistAnchorElement, setSpotlistAnchorElement] = useState(null)
  const handleSpotlistClick = (event) => {
    setSpotlistAnchorElement(event.currentTarget);
  };
  const handleSpotlistClose = () => {
    setSpotlistAnchorElement(null);
  };
  const spotlistOpen = Boolean(spotlistAnchorElement);
  const spotlistId = spotlistOpen ? 'simple-popover' : undefined;

  // Pour gérer l'affichage du Popover avec le menu More
  const [moreIconAnchorElement, setMoreIconAnchorElement] = useState(null)
  const handleMoreIconClick = (event) => {
    setMoreIconAnchorElement(event.currentTarget);
  };
  const handleMoreIconClose = () => {
    setMoreIconAnchorElement(null);
  };
  const moreIconOpen = Boolean(moreIconAnchorElement);
  const moreIconId = moreIconOpen ? 'simple-popover' : undefined;

  let nextPage ="/Comments/" + props.post.pk.toString() 

  let displayedSpot
  if (props.postScreen === "SpotFeed") {
    displayedSpot = props.spot
  } else if (props.postScreen === "NewsFeed") {
    let firstSubscribedSpotIndex = props.post.postSpot.findIndex(postSpotItem => (subscriptionsArray.findIndex(subscriptionItem => (subscriptionItem.subscriptionSpot === postSpotItem.pk)) !== -1))
    displayedSpot = props.post.postSpot[firstSubscribedSpotIndex]
    } else {
    displayedSpot = props.post.postSpot[0]
  }
  
  const navigate = useNavigate()
  dayjs.locale('fr')

  function IsNotExpired(props) {
    var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
    dayjs.extend(isSameOrAfter)
    let expiration = dayjs(props.timestamp).add(props.duration,'h')
    return(dayjs(expiration).isSameOrAfter(dayjs()))
  }

  function SendReport() {
    let nextPage ="/Report/" + props.post.pk.toString() + "/" + null
    navigate(nextPage)
  }

  function MoreButtonMenu() {
    return(
      <Popover
        dense
        id={moreIconId}
        open={moreIconOpen}
        anchorEl={moreIconAnchorElement}
        onClose={handleMoreIconClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList dense>
          <MenuItem onClick={(event) => {
            event.stopPropagation(); // Empêche la propagation vers la Box parent
            SendReport()}}>
            <ListItemIcon>
              <ReportProblemIcon/>
            </ListItemIcon>
            <ListItemText>
              Signaler
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <MoreHorizIcon/>
            </ListItemIcon>
            <ListItemText>
              Plus...
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    )
  }

  const authorGlobalBadge = () => {
    if (props.post.authorGlobalBadge) {
    return (
      <Avatar
      src={props.post.authorGlobalBadge}  
      sx={{ width: 15, height: 15, marginRight : 0.5, marginLeft : 0.5 }}
    />
      )
    } else {
    return null;
    }
  };

  function SpotChipsLine() {
    if (props.post.postSpot.length === 1) {
      return(
        <Box
          sx={{
            display: 'flex',
            overflow : "hidden",
            whiteSpace : "nowrap"
          }}
        >
          <Link
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              navigate("/Feed/"+ props.post.postSpot[0].pk)
            }}
            variant="subtitle2"
            color="primary"
            underline="hover"
            sx={{
              marginRight : 1,
            }}
          >
            {props.post.postSpot[0].spotName}
          </Link>
        </Box>
      )
    } else {
      return(
        <Box
          sx={{
            display: 'flex',
            overflow : "hidden",
            whiteSpace : "nowrap"
          }}
        >
          <Link
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              navigate("/Feed/"+ displayedSpot.pk)
            }}
            variant="subtitle2"
            color="primary"
            underline="hover"
            sx={{
              marginRight : 1,
              overflow : "hidden",
              whiteSpace : "nowrap"
            }}
          >
            {" " + displayedSpot.spotName}
          </Link>
          <Link
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              handleSpotlistClick(event)
            }}
            variant="subtitle2"
            color="primary"
            underline="hover"
            sx={{
              marginRight : 1
            }}
          >
            {"+ " + (props.post.postSpot.length - 1)}
          </Link>
          <Popover
            id={spotlistId}
            open={spotlistOpen}
            anchorEl={spotlistAnchorElement}
            onClose={handleSpotlistClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{display : "flex", flexDirection : "column", justifyContent : "flex-start", p: 2 }}>
              {props.post.postSpot.map(item =>
                  <Link 
                    onClick={(event) => {
                      event.stopPropagation(); // Empêche la propagation vers la Box parent
                      navigate("/Feed/"+ item.pk)
                    }}
                    key={item.pk} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}
                    >
                    {item.spotName}
                  </Link>
              )}
            </Box>
          </Popover>
        </Box>
      )
    }
  }

  function AuthorLine() {
    let nextPageProfile ="/OtherUserProfile/" + props.post.postAuthor.toString()
    return(
      <Box
        sx={{
          display: 'flex',
          overflow : "hidden",
          whiteSpace : "nowrap",
          alignItems : "center",
        }}
      >
        <Box 
          sx={{
          display : "flex",
          overflow : "hidden",
          whiteSpace : "nowrap",
        }}
        >
          <Link
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              navigate(nextPageProfile)
            }}
           variant="subtitle2" color={(props.post.authorIsHarbor ? "secondary" : "primary")} underline="hover">
            {props.post.authorNickname}
          </Link>
        </Box>
        {authorGlobalBadge()}
      </Box>
    )
  }

  function DateLine() {
    return(
      <Box
        sx={{
          display: 'flex',
          overflow : "hidden",
          whiteSpace : "nowrap",
          alignItems : "center",
        }}
      >
        <Typography
          variant="caption"
          color="grey"
        >
          {DateFromNow()}
        </Typography>
      </Box>
    )
  }

  function ReportMarkHeaderChip(props){
    if (IsNotExpired({timestamp : props.marker.post.postreportmarks.reportMarkTimestamp, duration : props.duration})) {
      return(
        <Chip size = "small" color="error" clickable={true} label="Voir sur la carte" icon={<AddLocationIcon fontSize="small"/>}
          onClick={(event) => {
            event.stopPropagation(); // Empêche la propagation vers la Box parent
            navigate("/MapPage" , {state : {zoomToSearch : {...props.marker.post.postreportmarks, nbComments : (props.marker.post.hasOwnProperty('comments') ?  props.marker.post.comments.length : props.marker.post.nbComments)}}})// Le nb de commentaires n'est pas dans le props.merket.post quand on vient de l'écran Post, donc on crée manuellement le champs pour alimenter le MarkerDetailCard pour le zoomToSearch
          }}
        /> 
      )
    } else {
      return(
        null
      )
    }
  }

  function ReportMarkHeader() {
    if (props.post.postType === "Report") {
      let markerTypeObject = reportTypesArray.find(item => (item.type === props.post.postreportmarks.reportMarkType))
      let markerDuration = markerTypeObject.hours
      let markerIcon = markerTypeObject.icon
      let markerLabel = markerTypeObject.label
      return(
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", marginLeft : 1, marginTop : 1}} >
          <Box
            component="img"
            sx={{
              display: 'inline-flex',
              maxHeight: 20,
              maxWidth: 20,
              marginRight : 0.5
            }}
            src={markerIcon}
          />
          <Typography
            variant="body2"
            color="primary"
            fontWeight="bold"
            sx={{
              marginRight : 0.5
            }}
          >
            {markerLabel}
          </Typography>
          <ReportMarkHeaderChip marker = {props} duration = {markerDuration}/>
        </Box>
      )
    } else return null
  }
  
  function DateFromNow() {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(props.post.postTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function PostHeader() {
    return(
      <Box
        sx={{
          display : "flex",
          flexDirection : "row",
          alignItems : "center"
        }}>
        <Avatar
          src={props.post.authorAvatarurl ? props.post.authorAvatarurl : null}
        />
        <Box
          sx={{
            display : "flex",
            flexGrow : 1,
            flexDirection : "column",
            marginLeft : 1,
            overflow : "hidden",
            whiteSpace : "nowrap"
          }}>
          {AuthorLine()}
          {SpotChipsLine()}
          {DateLine()}
        </Box>
        <IconButton
          onClick={(event) => {
            event.stopPropagation(); // Empêche la propagation vers la Box parent
            handleMoreIconClick(event)
          }}
          color="primary"
          variant="rounded"
        >
          <MoreHorizIcon/>
        </IconButton>
        {MoreButtonMenu()}
      </Box>
    )
  }

  function CardMediaContent() {
    if(props.post.postPicurl) {
      return (
        <CardMedia
          component="img"
          image={props.post.postPicurl}
          alt="Image"
          sx={{maxHeight : "80vh",maxWidth:"100%"}}
        />
      )
    }
  }

  function ToggleLike() {
    if (token) {
      let fetchArgument = {
        'likeAuthor' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet spotlistId sera écrasé par le userid dans l'API
        'likePost' : props.post.pk
      }
      if (postLikes.iLike) {
        // Si l'utilisateur aime  déjà : c'est un like à supprimer
        setPostLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes - 1,
          iLike : false
        }))
      } else {
        // Si l'utilisateur n'aime pas déjà : c'est un like à ajouter
        setPostLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes + 1,
          iLike : true
        }))
        // Lancement animation des points gagnés
        console.log('Comment -> Déclenchement animation points gagnés like')
        dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 1})
      }
      PostFunction({fetchTarget : 'toggleLike', fetchArgument : fetchArgument, token : token})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          console.log('Post.js -> Fin chargement API toggleLike')
        } else {
          console.log('Post.js -> Chargement API toggleLike en erreur')
          let errorMessage = "Impossible de prendre en compte votre like, vérifiez votre connexion"
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
        }
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('Post.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('Post.js -> Réception du profil à jour en échec')
          }
        })
      })
      .then(() => {
        // On lance la fonction de refresh du component parent
        console.log('Post.js -> Mise à jour du contenu')
        props.refreshFunction()
      })
    } else {
      navigate("/login")
    }
  }

  function LikeAndCommentButtons() {
    if (props.postScreen === "PostWithComments") {
      return(
        <Box sx={{display : "flex", flexDirection : "row", justifyContent : "flex-start"}}>
          <Button size="small"
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              ToggleLike()
            }}
          >
            {(postLikes.iLike ?
            <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
            :
            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
            )}
            {postLikes.nbLikes}
          </Button>
        </Box>
      )
    } else {
      return(
        <Box sx={{display : "flex", flexDirection : "row", justifyContent : "space-between"}}>
          <Button size="small"
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              ToggleLike()
            }}
          >
            {(postLikes.iLike ?
            <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
            :
            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
            )}
            {postLikes.nbLikes}
          </Button>
          <Button size="small"
            onClick={(event) => {
              event.stopPropagation(); // Empêche la propagation vers la Box parent
              navigate(nextPage)
            }}
          >
            <CommentIcon  color="primary" sx={{marginRight : 1}}/>
            {props.post.nbComments.toString() + "  commentaires"}
          </Button>
        </Box>
      )
    }
  }

  return (
    <Box
      sx={{
        position : "relative",
        display : "flex",
        flexDirection : "column",
        backgroundColor : "white",
        borderBottom : 15,
        borderColor : "#FCE2D4",
        padding : 1,
      }}
      onClick={() => {navigate(nextPage)}}
      >
      {PostHeader()}
      {ReportMarkHeader()}
      <Typography
        variant="body2"
        color="text.primary"
        style={{whiteSpace: 'pre-line'}} // Pour afficher les sauts de ligne
        sx={{
          margin : 1,
          overflowWrap : "break-word"
        }}
      >
        {props.post.postText}
      </Typography>
      {CardMediaContent()}
      {LikeAndCommentButtons()}
    </Box>
  );
}