export default function BoatTypes() {
    return(
        [
            {
                boatTypeName : "Voilier",
                boatTypeImg : "/static/boattype/Sailboat.svg"
            },
            {
                boatTypeName : "Bateau à moteur",
                boatTypeImg : "/static/boattype/Yacht.svg"
            },
            {
                boatTypeName : "Barque",
                boatTypeImg : "/static/boattype/Small.svg"
            },
            {
                boatTypeName : "Commercial",
                boatTypeImg : "/static/boattype/Cargo.svg"
            },
        ]
    )
}
