import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import PatchFunction from '../API/patchFunction';
import GetFunction from '../API/getFunction';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import BackButton from '../Navigation/BackButton';

import Container from '@mui/material/Container';
import {useState, Fragment} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Resizer from "react-image-file-resizer";
  
import { useNavigate } from 'react-router-dom'

import BoatTypes from '../Profile/BoatTypes'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
        
export default function EditProfile() {

  const dispatch = useDispatch()

  const boatTypes = BoatTypes()

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const [profil, setProfil] = useState({
    ...myProfile,
    existingUserPicUrlAction : "keep", // Pour tracer quand l'utilisateur veut suprimer sa photo actuelle
    newPicFile : null,
    newAvatarFile : null,
    isFetching : false,
    isResizingPic : false,
    isResizingAvatar : false,
});

  const navigate = useNavigate()

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setProfil({
        ...profil,
        isResizingPic : true,
        isResizingAvatar : true,
        existingUserPicUrlAction : "keep"
      })
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,    // Largeur max
          300,    // Hauteur max
          "JPEG",  // Format de sortie
          90,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('EditProfile -> Image compressée')
            setProfil(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          0,       // Largeur min
          0        // Hauteur min
        );
      } catch (err) {
        console.log('EditProfile -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }

      // Lancement de la compression de l'avatar
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          50,    // Largeur max
          50,    // Hauteur max
          "JPEG",  // Format de sortie
          70,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('EditProfile -> Avatar compressé')
            setProfil(prevState => ({
              ...prevState,
              isResizingAvatar : false,
              newAvatarFile : uri
            }))
          },
          "file",   // Type de sortie
          30,       // Largeur min
          30        // Hauteur min
        );
      } catch (err) {
        console.log('EditProfile -> Echec compression avatar')
        let errorMessage = "Votre avatar n'a pas pu être créé."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setProfil({
      ...profil,
      newPicFile:null,
      newAvatarFile:null,
      existingUserPicUrlAction : "delete"
    })
  };

  const profilePicture = () => {
    if (profil.newPicFile) {
      return (
        <Avatar
          src={URL.createObjectURL(profil.newPicFile)}  
          sx={{ width: 200, height: 200 }}
        />
      )
    } else if ((profil.userPicurl) && (profil.existingUserPicUrlAction === "keep")) {
      return (
        <Avatar
          src={profil.userPicurl}  
          sx={{ width: 200, height: 200 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 200 }} />
      );
    }
  };

  const PicAddButton = () => {
    if (((!profil.userPicurl)&&(!profil.newPicFile)) || ((profil.userPicurl)&&(profil.existingUserPicUrlAction === "delete"))) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
          type="submit"
          variant="contained"
          loading={(profil.isResizingPic)||(profil.isResizingAvatar)}
        >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (((!profil.userPicurl)&&(profil.newPicFile)) || ((profil.userPicurl)&&(profil.existingUserPicUrlAction === "keep"))) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
          type="submit"
          variant="contained"
          loading={(profil.isResizingPic)||(profil.isResizingAvatar)}
        >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (((!profil.userPicurl)&&(profil.newPicFile)) || ((profil.userPicurl)&&(profil.existingUserPicUrlAction === "keep"))) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          endIcon={<CancelIcon />}
          onClick={onPicDelete}
        />
      );
    }
  }

  const handleSubmitChange = () => {
    UpdateMyProfile()
  };

  const handleSubmitCancel = () => {
    navigate("/Profile", {replace : true})
  };

  const handleChange = (event) => {
    let newValue = event.target.value
    if (event.target.id === "userBoatName" || event.target.id === "userBoatModel") {
      newValue = event.target.value.slice(0,20)
    } else if (event.target.id === "userBoatHarbor") {
      newValue = event.target.value.slice(0,50)
    }
    setProfil({...profil,
      [event.target.id]:newValue,
    })
  };

  const handleChangeCheck = (event) => {
    setProfil({...profil,[event.target.id]:event.target.checked})
  };

  const handleChangeSelect = (event) => {
    setProfil({...profil,userBoatType:event.target.value})
  };

  const handleChangeSwitch = (event) => {
    setProfil({...profil,[event.target.id]:event.target.checked})
  };

  function userBoatTypeError() {
    return (profil.userDisplayBoatType && profil.userBoatType === 0)
  }

  function userBoatNameError() {
    return (profil.userDisplayBoatName && (!profil.userBoatName || profil.userBoatName.trim() === ""))
  }

  function userBoatModelError() {
    return (profil.userDisplayBoatModel && (!profil.userBoatModel || profil.userBoatModel.trim() === ""))
  }

  function userBoatHarborError() {
    return (profil.userDisplayBoatHarbor && (!profil.userBoatHarbor || profil.userBoatHarbor.trim() === ""))
  }

  function UpdateMyProfile() {
    setProfil(prevState => ({...prevState, 
      isFetching : true
    }))
    console.log('EditProfile.js -> Chargement API sendMyProfile')

    // On crée une FormData, donnée structurée indispensable pour envoyer un fichier au back-end
    // Il faut donc passer tout le profil à travers ce FormData pour pouvoir modifier le profil en une fois
    const newProfileFormData = new FormData();
    
    if (profil.userDescription && profil.userDescription.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userDescription", profil.userDescription)
    } else {
      newProfileFormData.append("userDescription", "")
    }

    if (profil.userBoat && profil.userBoat.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoat", profil.userBoat)
    } else {
      newProfileFormData.append("userBoat", "")
    }

    newProfileFormData.append("userDisplayBoatType", profil.userDisplayBoatType)
    if (profil.userBoatType) { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatType", profil.userBoatType)
    } else {
      newProfileFormData.append("userBoatType", 0)
    }

    newProfileFormData.append("userDisplayBoatName", profil.userDisplayBoatName)
    if (profil.userBoatName && profil.userBoatName.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatName", profil.userBoatName)
    } else {
      newProfileFormData.append("userBoatName", "")
    }

    newProfileFormData.append("userDisplayBoatModel", profil.userDisplayBoatModel)
    if (profil.userBoatModel && profil.userBoatModel.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatModel", profil.userBoatModel)
    } else {
      newProfileFormData.append("userBoatModel", "")
    }

    newProfileFormData.append("userDisplayBoatHarbor", profil.userDisplayBoatHarbor)
    if (profil.userBoatHarbor && profil.userBoatHarbor.trim() !== "") { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newProfileFormData.append("userBoatHarbor", profil.userBoatHarbor)
    } else {
      newProfileFormData.append("userBoatHarbor", "")
    }

    if (profil.newPicFile) {
      newProfileFormData.append("userPicurl", profil.newPicFile,profil.newPicFile.name)
      newProfileFormData.append("userAvatarurl", profil.newAvatarFile,profil.newAvatarFile.name)
    }
    if (profil.existingUserPicUrlAction === "delete") {
      newProfileFormData.append("userPicurl",new File([], ''))
      newProfileFormData.append("userAvatarurl",new File([], ''))
    }
    newProfileFormData.append("userMailNotification", profil.userMailNotification)
    newProfileFormData.append("userPositionShare", myProfile.userPositionShare) // Ne change pas

    PatchFunction({fetchTarget:'sendMyProfileForm', fetchObjectId:myProfile.pk, fetchArgument: newProfileFormData, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('EditProfile.js -> Fin chargement API sendMyProfile')
        setProfil(prevState => ({...prevState, 
          fetchStatus : "OK",
          newPicFile : null,
          newAvatarFile : null,
        }))
        console.log('EditProfile.js -> Fetch du nouveau myProfile')
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('EditProfile.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('EditProfile.js -> Réception du profil à jour en échec')
          }
        })
        navigate(-1)
      } else {
        console.log('EditProfile.js -> Envoi sendMyProfile en échec')
        setProfil(prevState => ({...prevState,
          isFetching : false
        }))
        let errorMessage = "Votre profil n'a pas pu être mis à jour. Vérifier votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function EditProfileForm() {
    if (myProfile) {
      return (
        <Container component="main" maxWidth="sm">
        <Paper elevation={10}>
          <Box
          sx={{
            marginTop: 5,
            marginRight: 1,
            marginLeft: 1,
            marginBottom : 5,
            paddingTop: 2,
            paddingRight: 2,
            paddingLeft: 2,
            paddingBottom : 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor : "white",
            borderRadius : 3
          }}
        >
          <BackButton/>
          {profilePicture()}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft:2,
            }}
          >
            {PicAddButton()}
            {PicChangeButton()}
            {PicDeleteButton()}
          </Box>
            <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              {profil.userNickname}
            </Typography>

            <TextField
              fullWidth
              id="userDescription"
              label={(myProfile.userIsHarbor ? "Remplissez le mot de la capitainerie" : "Présentez-vous aux autres utilisateurs")}
              onChange={(event) => handleChange(event)}
              value={profil.userDescription}
              sx={{marginTop : 2, marginBottom : 1}}
              multiline
              rows={4}
            />
            {(myProfile.userIsHarbor ?
              null
              :
              <TextField
                fullWidth
                id="userBoat"
                label="Parlez-nous de votre/vos bateau(x) !"
                onChange={(event) => handleChange(event)}
                value={profil.userBoat}
                sx={{marginTop : 1, marginBottom : 2}}
                multiline
                rows={4}
              />
            )}
            <Typography variant={"body1"}>
              Afficher sur mon profil :
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={profil.userDisplayBoatType} onChange={handleChangeCheck} color="primary" id="userDisplayBoatType"/>
              }
                label={
                  <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                    <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                      Le type de mon bateau
                    </Typography>
                    <Select
                      id="userBoatType"
                      value={profil.userBoatType}
                      label="Type"
                      onChange={handleChangeSelect}
                      required = {profil.userDisplayBoatType}
                      disabled={!profil.userDisplayBoatType}
                      error={userBoatTypeError()}
                    >
                      <MenuItem value = {0}>Choisissez un type</MenuItem>
                      {boatTypes.map((item,index) => {
                        return(
                          <MenuItem key={index} value = {index + 1}>{"..." + item.boatTypeName}</MenuItem>
                        )
                      })}
                    </Select>
                  </Box>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={profil.userDisplayBoatName} onChange={handleChangeCheck} color="primary" id="userDisplayBoatName"/>}
                label={
                  <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                    <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                      Le nom de mon bateau
                    </Typography>
                    <TextField
                      required = {profil.userDisplayBoatName}
                      sx={{width : "30%"}}
                      id="userBoatName"
                      label="Nom"
                      onChange={(event) => handleChange(event)}
                      value={profil.userBoatName}
                      disabled={!profil.userDisplayBoatName}
                      error={userBoatNameError()}
                    />
                </Box>
              }
              />
              <FormControlLabel
                control={<Checkbox checked={profil.userDisplayBoatModel} onChange={handleChangeCheck} color="primary" id="userDisplayBoatModel"/>}
                label={
                  <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                    <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                      Le modèle de mon bateau
                    </Typography>
                    <TextField
                      required = {profil.userDisplayBoatModel}
                      sx={{width : "30%"}}
                      id="userBoatModel"
                      label="Modèle"
                      onChange={(event) => handleChange(event)}
                      value={profil.userBoatModel}
                      disabled={!profil.userDisplayBoatModel}
                      error={userBoatModelError()}
                    />
                  </Box>
                }
              />
              <FormControlLabel
                control={<Checkbox checked={profil.userDisplayBoatHarbor} onChange={handleChangeCheck} color="primary" id="userDisplayBoatHarbor"/>}
                label={
                  <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                    <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                      Mon port d'attache
                    </Typography>
                    <TextField
                      required = {profil.userDisplayBoatHarbor}
                      sx={{width : "30%"}}
                      id="userBoatHarbor"
                      label="Port d'attache"
                      onChange={(event) => handleChange(event)}
                      value={profil.userBoatHarbor}
                      disabled={!profil.userDisplayBoatHarbor}
                      error={userBoatHarborError()}
                    />
                  </Box>
                }
              />
            </FormGroup>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
                Notifications par mail
              </Typography>
              <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                Nous vous préviendrons par mail chaque fois qu'un utilisateur vous enverra un message ou réagira à une de vos publications.
                Votre adresse mail ne sera JAMAIS utilisée dans un but commercial, et ne sra JAMAIS communiquée à qui que ce soit.
              </Typography>
              <FormControlLabel
                sx={{marginTop : 1}}
                control={<Switch checked={profil.userMailNotification} onChange={handleChangeSwitch} color="primary" id="userMailNotification"/>}
                label={profil.userMailNotification ?
                  <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                    Vous recevrez un mail pour vous avertir d'une nouvelle notification
                  </Typography>
                :
                  <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                    Vous ne recevrez pas de mail en cas de notification dans l'application
                  </Typography>
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
                marginTop : 2
              }}
            >
              <Button
                variant="contained"
                endIcon={<CancelIcon />}
                onClick={handleSubmitCancel}
                sx={{margin : 2}}
                color="error"
              >
                Annuler
              </Button>
              <LoadingButton
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleSubmitChange}
                loading={profil.isFetching}
                sx={{margin : 2}}
                disabled={userBoatTypeError() || userBoatNameError() || userBoatModelError() || userBoatHarborError()}
                >
                Valider
              </LoadingButton>
          </Box>
        </Box>
        </Paper>
      </Container>
      );
    } else {
      return null
    }
  }

  return (
    EditProfileForm()
  );
}