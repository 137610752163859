import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import GetFunction from '../API/getFunction';
import PostFunction from '../API/postFunction';

import BackButton from '../Navigation/BackButton';

import { useState, useEffect, Fragment } from 'react';

import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material';

import BoatTypes from '../Profile/BoatTypes'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

export default function OtherUserProfile() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  // On récupère le userId et le chatButtonDisplay passés en paramètre du lien
  let {userId} = useParams()

  const [userProfile, setUserProfile] = useState({
    userProfile: null,
    isFetching: true,
  })

  // Types de bateaux
  const boatTypes = BoatTypes()
  
  useEffect(() => {
    console.log('OtherUserProfile.js -> useEffect : chargemment du profil utilisateur')
    LoadOtherUserProfile()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

    // Fonction de chargement du contenu du profil, lancée dans le useEffect
  function LoadOtherUserProfile() {
    GetFunction({fetchTarget : 'getOtherUserProfile', fetchArgument:userId, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('OtherUserProfile.js -> Fin chargement API getOtherUserProfile')
        console.log('OtherUserProfile.js -> Mise à jour du statut otherUserProfile')
        setUserProfile(prevState => ({...prevState, userProfile : response.data[0], isFetching : false})) 
      } else {
        console.log('OtherUserProfile.js -> Impossible de charger le contenu du profil')
        setUserProfile(prevState => ({...prevState, isFetching : false})) 

        let errorMessage = "Impossible d'afficher le profil de l'utilisateur , vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})

      }
    })
  }

  function ChatButton() {
    if (userProfile.userProfile) {
      return (
        <Button
          onClick={ChatWithUser}
          variant="contained"
          endIcon={<EditIcon/>}
          sx={{ margin : 2}}
          disabled = {(userProfile.userProfile.pk === myProfile.pk)}
          >
          Ecrire à {userProfile.userProfile.userNickname}          
        </Button>
      );
    } else return null
  }

  const profilePicture = () => {
    return (
      <Avatar
        src={userProfile.userProfile.userPicurl ? userProfile.userProfile.userPicurl : null}  
        sx={{ width: 100, height: 100, mb : 1 }}
      />
    )
  };

  const profileGlobalBadge = () => {
    if (userProfile.userProfile.userGlobalBadge) {
      return (
        <Avatar
        src={userProfile.userProfile.userGlobalBadge}  
        sx={{ width: 20, height: 20 }}
      />
      )
    } else {
    return null;
    }
  };

  function BoatTypeIcon() {
    if (userProfile.userProfile.userDisplayBoatType) {
      return(
        <Box
          component="img"
          sx={{
          height : 50,
          width : 50
          }}
          src={boatTypes[userProfile.userProfile.userBoatType-1].boatTypeImg}
        />
      )
    } else return null
  }

  function UserProfileScreen() {
    if (userProfile.userProfile) {
      if (!userProfile.isFetching) {
        return (
          <Box sx={{display : "flex", flexDirection : "column", width : "100%", height : "100%"}}>
            <Box sx={{
              display : "flex",
              flex : 1,
              flexDirection : "column",
              width : "100%",
              margin : 1,
              }}
              overflow = "auto"
            >
              <Box sx={{display : "flex", flexDirection : "column", alignItems : "center"}}>
                <Box sx={{display : "flex", alignItems : "center", mb : 1}}>
                  {profileGlobalBadge()}
                  <Typography variant="h5" color={"secondary"} fontWeight={'bold'} textAlign={'center'} sx={{whiteSpace : "pre"}}>
                    {" " + userProfile.userProfile.userNickname}
                  </Typography>
                </Box>
                {profilePicture()}
              </Box>
              <Divider sx={{mt : 1, mb : 1}}/>
              <Box sx={{display : "flex", flexDirection : "row"}}>
                {BoatTypeIcon()}
                <Box sx={{display : "flex", flexDirection : "column", overflow : "hidden", whiteSpace : "nowrap"}}>
                  <Typography variant="body1" color={"primary"}>
                    {(userProfile.userProfile.userDisplayBoatName ?
                      userProfile.userProfile.userBoatName
                      :
                      null
                    )}
                    {(userProfile.userProfile.userDisplayBoatModel ?
                        userProfile.userProfile.userDisplayBoatName ?
                          " (" + userProfile.userProfile.userBoatModel + ")"
                          :
                          userProfile.userProfile.userBoatModel
                      :
                      null
                    )}
                  </Typography>
                  {(userProfile.userProfile.userDisplayBoatHarbor ?
                    <Typography variant="body1" color={"primary"}>
                      {userProfile.userProfile.userBoatHarbor}
                    </Typography>
                    :
                    null
                  )}
                </Box>
              </Box>
              <Divider sx={{mt : 1, mb : 1}}/>
              <Typography variant="h6" fontWeight = "bold" color={"secondary"}>
                {userProfile.userProfile.userIsHarbor ? "Le mot de la capitainerie": "Quelques mots sur moi"}
              </Typography>
              <Typography variant="body1" color={"primary"}>
                {userProfile.userProfile.userDescription ? userProfile.userProfile.userDescription : null}
              </Typography>

              {(userProfile.userProfile.userIsHarbor ?
                null
                :
                <Fragment>
                  <Divider sx={{mt : 1, mb : 1}}/>
                  <Typography variant="h6" fontWeight = "bold" color={"secondary"}>
                    Quelques mots sur mon bateau
                  </Typography>
                  <Typography variant="body1" color={"primary"}>
                    {userProfile.userProfile.userBoat ? userProfile.userProfile.userBoat : null}
                  </Typography>
                </Fragment>
              )}
            </Box>
            {ChatButton()}
          </Box>
        )
      } else {
        return (
          <Container component="main"
            sx={{
            backgroundColor: "#DDDDDD",
            }}
          >
            <CircularProgress />
          </Container>
        )
      }
    }
  }

  function ChatWithUser() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === userProfile.userProfile.pk))
    if (userIndexInSubscription === -1) { // On vérifie si un chat avec l'utilisateur existe déjà car sinon il faut le créer avant d'aller vers l'écran de chat
      console.log('OtherUserProfile.js -> Pas de chat commencé avec cet utilisateur : on crée un nouveau header')
      setUserProfile(prevState => ({...prevState, isFetching : true})) 
      let headerToCreate = {
        "chatroomsubscriptions": [
          {
              "subscriptionUser": myProfile.pk,
              "subscriptionUnreadMessages": 0
          },
          {
              "subscriptionUnreadMessages": 0,
              "subscriptionUser": userProfile.userProfile.pk
          }
        ]
      }
      PostFunction({fetchTarget : 'createChatHeader', fetchArgument : headerToCreate, token : token})
      .then(response => {
        let newChatRoom = response.data.chatroomsubscriptions[0].subscriptionChatroom
        setUserProfile(prevState => ({...prevState, isFetching : false})) 
        if (response.fetchStatus === 'Ok')  {
          console.log('OtherUserProfile.js -> Chargement du chatHeadersArray à jour')
          GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
          .then((response) => {
            if (response.fetchStatus === 'Ok') {
              console.log('OtherUserProfile.js -> Chargement du chatHeadersArray dans le state Redux')
              dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
            } else {
              console.log('OtherUserProfile.js -> Impossible de charger la liste des conversations à jour')
            }
          })
          let nextPage = "/Chat/" + newChatRoom + "/" + userProfile.userProfile.pk
          navigate (nextPage)
        } else {
          console.log('OtherUserProfile.js -> Impossible de créer une conversation avec cet utilisateur')
        }
      })
    } else {
      console.log('OtherUserProfile.js -> Il y a déjà un chat commencé avec cet utilisateur : on y va')
      let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
      let nextPage = "/Chat/" + existingChatroom + "/" + userProfile.userProfile.pk
      navigate (nextPage)
    }
  }

  return(
    <Fade in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, zIndex : 1100}}>
      <BackButton/>
        {/* Fond grisé qui renvoie vers la page précédente */}
        <Box
          onClick = {() => {navigate(-1)}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "#FCE2D4", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1, paddingRight : 1.5}} elevation={24} >
          {/* Croix pour fermer la fenêtre */}
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate(-1)}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <UserProfileScreen/>
        </Paper>
      </Box>
    </Fade>
  )
}